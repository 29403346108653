<template>
  <div class="features columnd w100 text-start">
    <h1 class="f22-700 c-lightOrange">Over 350 ways to buy and sell Bitcoin</h1>
    <div class="rowd space-between">
      <div class="items columnd space-between">
        <div class="rowd space-between item-row">
          <div class="item rowd">
            <inline-svg
              class="icon-40-40"
              :src="require('../../assets/Icons/Bank.svg')"
              alt="inja ax ee"
            />
            <div class="text columnd">
              <span class="f14-700 title c-darkBlue"> Bank Transfers </span>
              <span class="f14-400 body c-darkGrey">
                Radin makes it safe and easy for you to and buy Bitcoin using
                Bank Transfers in just 5 simple steps.
              </span>
            </div>
          </div>
          <div class="item rowd">
            <inline-svg
              class="icon-40-40"
              :src="require('../../assets/Icons/giftCard.svg')"
              alt="inja ax ee"
            />
            <div class="text columnd">
              <span class="f14-700 title c-darkBlue"> Gift Card </span>
              <span class="f14-400 body c-darkGrey">
                Paxful makes it safe and easy for you to trade Bitcoin. Find the
                best
              </span>
            </div>
          </div>
        </div>
        <div class="rowd space-between item-row">
          <div class="item rowd">
            <inline-svg
              class="icon-40-40"
              :src="require('../../assets/Icons/OnlineWallet.svg')"
              alt="inja ax ee"
            />
            <div class="text columnd">
              <span class="f14-700 title c-darkBlue"> Online Wallets </span>
              <span class="f14-400 body c-darkGrey">
                Don’t want to leave the house? You can use a number of popular
                payment apps.
              </span>
            </div>
          </div>
          <div class="item rowd">
            <inline-svg
              class="icon-40-40"
              :src="require('../../assets/Icons/digitalCurrencies.svg')"
              alt="inja ax ee"
            />
            <div class="text columnd">
              <span class="f14-700 title c-darkBlue"> Digital Currencies </span>
              <span class="f14-400 body c-darkGrey">
                Trade your digital currencies or tokens for Bitcoin addedd
                instantly to your digital wallet.
              </span>
            </div>
          </div>
        </div>
        <div class="rowd space-between item-row">
          <div class="item rowd">
            <inline-svg
              class="icon-40-40"
              :src="require('../../assets/Icons/cachepayment.svg')"
              alt="inja ax ee"
            />
            <div class="text columnd">
              <span class="f14-700 title c-darkBlue"> Cash Payment </span>
              <span class="f14-400 body c-darkGrey">
                Trade your digital currencies or tokens for Bitcoin added
                instantly to your digital wallet.
              </span>
            </div>
          </div>
          <button class="secondary-btn dflex align-center">
            <span> ٰView All Offers </span>
            <inline-svg :src="require('../../assets/Icons/longArrow.svg')" />
          </button>
        </div>
      </div>
      <inline-svg
        class="featuresIcons"
        :src="require('../../assets/Icons/featuresIcons.svg')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Features",
};
</script>

<style lang="scss" scoped>
.secondary-btn {
  margin-top: 30px;
  border: none !important;
  max-width: 180px;
  max-height: 38px;
  justify-self: center;
  gap: 8px;
  svg {
    fill: var(--darkOrange);
  }
  &:hover {
    svg {
      fill: var(--white);
    }
  }
}
.item-row {
  gap: 120px;
}
.item {
  gap: 15.1px;
  max-width: 300px;
  .text {
    gap: 8px;
  }
  svg {
    fill: var(--darkBlue);
    transition: 500ms;
  }
  span {
    transition: 500ms;
  }
  &:hover {
    svg {
      fill: var(--darkCryan) !important;
    }
    .title {
      color: var(--darkCryan) !important;
    }
    .body {
      color: var(--black) !important;
    }
  }
}
.features {
  gap: 40px;
}
@media only screen and(max-width:800px) {
  .featuresIcons {
    display: none;
  }
  .item-row {
    flex-direction: column !important;
  }
  .items {
    gap: 120px;
  }
  .features {
    align-items: center;
  }
  h1 {
    align-self: flex-start;
  }
  .item {
    max-width: unset !important;
  }
}
</style>
