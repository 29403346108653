<template>
  <div class="table columnd w100">
    <h2 class="rowd align-center text-start w100">
      <span class="f22-700 c-lightOrange"> Top 10 </span>
      <span class="opa0">i</span>
      <span class="f16-700 c-darkGrey"> Exchanges </span>
    </h2>
    <div class="table-container w100">
      <div class="btns rowd">
        <button
          @click="buy = true"
          :class="{ 'toggle-active': buy }"
          class="toggle"
        >
          <span class="f16-700"> Buyers </span>
          <transition name="borderrotate">
            <span v-if="buy" class="underline"></span>
          </transition>
        </button>

        <button
          @click="buy = false"
          :class="{ 'toggle-active': !buy }"
          class="toggle"
        >
          <span class="f16-700"> Sellers </span>
          <transition name="borderrotate">
            <span v-if="!buy" class="underline"></span>
          </transition>
        </button>
      </div>
      <div class="w100 table columnd">
        <div class="header-container columnd">
          <div class="header rowd w100 space-between">
            <span
              class="f12-700 c-darkGrey"
              v-for="(item, index) in header"
              :key="index"
            >
              {{ item }}
            </span>
          </div>
          <div class="table-border"></div>
        </div>
        <div class="row-container columnd">
          <div v-for="i in 5" :key="i" class="row columnd">
            <div class="inside-row1 rowd"></div>
            <div class="inside-row2 rowd space-between"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Table",
  data() {
    return {
      buy: false,
      header: [
        "By From",
        "Pay With",
        "Rate",
        "Avg.Tarde Speed",
        "Rate Per BTC",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.row-container {
  .row {
    border-bottom: 1px solid var(--lightGrey);
    padding: 16px;
    gap: 8px;
    min-height: 126px;
  }
}
.table-container {
  gap: 28px;
}
.table {
  gap: 28px;
  //   temporary
  height: 700px;
  .header-container {
    gap: 8px;
  }
  overflow: scroll;
}
</style>
