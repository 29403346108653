<template>
  <div class="cards-container w100 rowd space-between">
    <div
      @mouseenter="item.underline = true"
      @mouseleave="item.underline = false"
      v-for="(item, index) in cards"
      :key="index"
      class="card text-start columnd"
    >
      <div class="svg-container" :class="item.class">
        <inline-svg :src="item.img" alt="" />
      </div>
      <span class="f14-700 c-darkBlue"> {{ item.title }} </span>
      <p class="f14-400 c-darkGrey">{{ item.text }}</p>
      <transition name="scalex">
        <div v-if="item.underline" class="underline"></div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cards",
  data() {
    return {
      cards: [
        {
          img: require("../../assets/Icons/secure.svg"),
          title: "Secured by Escrow",
          text: "Paxful makes it safe and easy for you to trade Bitcoin. Find the best",
          underLine: false,
          class: "secure",
        },
        {
          img: require("../../assets/Icons/Users.svg"),
          title: "Lots of Happy Users",
          text: "Paxful makes it safe and easy for you to trade Bitcoin. Find the best",
          underLine: false,
          class: "Users",
        },
        {
          img: require("../../assets/Icons/Deal.svg"),
          title: "Thousands of Trusted Offers",
          text: "Paxful makes it safe and easy for you to trade Bitcoin. Find the best",
          underLine: false,
          class: "Deal",
        },
        {
          img: require("../../assets/Icons/WalletCard.svg"),
          title: "Billions in Global Volume",
          text: "Paxful makes it safe and easy for you to trade Bitcoin. Find the best",
          underLine: false,
          class: "WalletCard",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.WalletCard {
  background: var(--yellow);
}
.Deal {
  background: var(--lightGreen);
}
.Users {
  background: var(--lightYellow);
}
.secure {
  background: var(--llCryan);
}
</style>
