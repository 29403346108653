<template>
  <div class="blogs columnd w100 text-start">
    <h1 class="f22-700 c-lightOrange">Blogs</h1>
    <carousel :breakpoints="breakpoints">
      <slide class="slider-me" v-for="slide in 10" :key="slide">
        <div class="blog-item columnd">
          <img src="../../assets/test/image6.svg" alt="" />
          <div class="texts columnd">
            <h3 class="text-start f16-700 c-darkBlue">
              Thousands of Trusted Offers
            </h3>
            <p class="text-start f14-400 c-darkGrey">
              Paxful makes makes it safe and easy for you to trade Bitcoin. ...
            </p>
          </div>
          <div class="rates rowd space-between">
            <div class="rete-item rowd align-center">
              <inline-svg :src="require('../../assets/Icons/eye.svg')" />
              <span class="f10-400 c-medGrey"> 2min </span>
            </div>
            <div class="rete-item rowd">
              <inline-svg :src="require('../../assets/Icons/Like.svg')" />
              <span class="f10-400 c-medGrey"> 23 Likes </span>
            </div>
          </div>
        </div>
      </slide>
      <template #addons>
        <pagination />
      </template>
    </carousel>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";
export default {
  name: "Blogs",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      breakpoints: {
        // 700px and up
        300: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        700: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        1900: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.slider-me {
  &:hover {
    cursor: pointer;
  }
}
.blog-item {
  gap: 16px;
  .texts {
    gap: 8px;
  }
}
.blogs {
  gap: 40px;
}
</style>
