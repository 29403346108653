<template>
  <div class="find-order w100 columnd">
    <div class="btns rowd">
      <button
        @click="buy = true"
        :class="{ 'toggle-active': buy }"
        class="toggle"
      >
        <span class="f16-700"> Buy </span>
        <transition name="borderrotate">
          <span v-if="buy" class="underline"></span>
        </transition>
      </button>

      <button
        @click="buy = false"
        :class="{ 'toggle-active': !buy }"
        class="toggle"
      >
        <span class="f16-700"> Sell </span>
        <transition name="borderrotate">
          <span v-if="!buy" class="underline"></span>
        </transition>
      </button>
    </div>

    <div style="gap: 8px" class="columnd">
      <div @click="selectCoinDrop" class="selectCoin">
        <span class="drop-title text-start f12-700 c-darkGrey">
          {{ buy ? "Buy" : "Sell" }}
        </span>
        <div class="coin-selector rowd space-between w100 align-center">
          <div class="icon align-center">
            <img class="coin-img" :src="selectedCoin.img" alt="" />
            <span class="selected-icon c-medGrey f16-400">
              {{ selectedCoin.code }}
            </span>
          </div>
          <inline-svg
            class="medgrey-svg"
            :src="require('../../assets/Icons/arrow.svg')"
          />
        </div>
        <transition name="inputdrop">
          <div v-if="coinDrop" class="input-drop-down">
            <div v-for="(item, index) in coins" :key="index" class="item">
              <img class="coin-img" :src="item.img" alt="" />
              <span> {{ item.code }} </span>
            </div>
          </div>
        </transition>
      </div>
      <div class="unitprice rowd align-center">
        <span class="f12-400 c-darkGrey">
          1 {{ selectedCoin.code + "=" }}
        </span>
        <span class="f12-700">
          {{ $toFuncs.toLocal(selectedCoin.unitPrice) }}
        </span>
        <span class="f12-400 c-darkGrey"> {{ selectedPriceType }} </span>
      </div>
    </div>
    <div style="gap: 8px" class="columnd">
      <div @click="selectPayment" class="selectCoin">
        <span class="drop-title text-start f12-700 c-darkGrey">
          {{ buy ? "Pay Via" : "Get Paid Via" }}
        </span>
        <div class="coin-selector rowd space-between w100 align-center">
          <div class="icon align-center">
            <span class="selected-icon c-medGrey f16-400">
              Select payment method
            </span>
          </div>
          <inline-svg
            class="medgrey-svg"
            :src="require('../../assets/Icons/arrow.svg')"
          />
        </div>
        <transition name="inputdrop">
          <div v-if="paymentDrop" class="input-drop-down">
            <div
              v-for="(item, index) in paymentMethods"
              :key="index"
              class="item"
            >
              <span> {{ item.paymentMethod }} </span>
            </div>
          </div>
        </transition>
      </div>
      <div class="tags rowd align-center">
        <span
          class="f12-400 c-darkGrey"
          v-for="(item, index) in tags"
          :key="index"
        >
          {{ item }}
        </span>
      </div>
    </div>
    <div style="gap: 8px" class="columnd">
      <div @click="selectPriceType" class="selectCoin">
        <span class="drop-title text-start f12-700 c-darkGrey">
          {{ buy ? "I Want To Spend" : "I Want To Get" }}
        </span>
        <div class="coin-selector rowd space-between w100 align-center">
          <div class="icon align-center">
            <span class="selected-icon c-medGrey f16-400">
              {{ selectedPriceType }}
            </span>
          </div>
          <inline-svg
            class="medgrey-svg"
            :src="require('../../assets/Icons/arrow.svg')"
          />
        </div>
        <transition name="inputdrop">
          <div v-if="priceTypeDrop" class="input-drop-down">
            <div v-for="(item, index) in priceTypes" :key="index" class="item">
              <span> {{ item.paymentMethod }} </span>
            </div>
          </div>
        </transition>
      </div>
      <div class="rowd align-center">
        <span class="f12-400 c-darkGrey">
          Minimum : {{ 10 }} {{ selectedPriceType }}
        </span>
      </div>
    </div>
    <button class="primary-btn">Find Order</button>
  </div>
</template>

<script>
export default {
  name: "HomeFindOrder",
  data() {
    return {
      buy: false,
      coinDrop: false,
      paymentDrop: false,
      priceTypeDrop: false,
      //fake data
      tags: ["Bank Transfer", "PayPall", "Skrill"],
      selectedPriceType: "USDT",
      selectedCoin: {
        coin: "ETHERIUM",
        code: "ETH",
        unitPrice: 87478.4,
        img: require("../../assets/test/ETHEREUM.png"),
      },

      coins: [
        {
          coin: "ETHERIUM",
          code: "ETH",
          unitPrice: 87478.4,
          img: require("../../assets/test/ETHEREUM.png"),
        },
        {
          coin: "ETHERIUM",
          code: "ETH",
          unitPrice: 87478.4,
          img: require("../../assets/test/ETHEREUM.png"),
        },
        {
          coin: "ETHERIUM",
          code: "ETH",
          unitPrice: 87478.4,
          img: require("../../assets/test/ETHEREUM.png"),
        },
      ],
    };
  },
  methods: {
    selectCoinDrop() {
      this.coinDrop = !this.coinDrop;
    },
    selectPayment() {
      console.log("change payment");
    },
    selectPriceType() {
      console.log("change payment");
    },
    clickOut(e) {
      if (!e.target.closest(".input-drop-down,.selectCoin")) {
        this.coinDrop = false;
        this.paymentDrop = false;
        this.priceTypeDrop = false;
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("click", this.clickOut);
  },
  created() {
    window.addEventListener("click", this.clickOut);
  },
};
</script>

<style lang="scss" scoped>
.unitprice span {
  word-spacing: 2px;
}
.find-order {
  max-width: 500px;
  gap: 24px;
  padding: 32px;
  background: white;
  border-radius: 24px;
  transform: translateY(-100px);
}
.icon {
  gap: 4px;
}
</style>
