<template>
  <div class="banner w100 rowd space-between align-center">
    <div class="fakedi"></div>
    <div class="text-start banner-title columnd">
      <h1 class="c-darkBlue f36-700">Buy And Sell</h1>
      <h1 class="c-darkBlue f36-700">Cryprocurrencies</h1>
      <h1 class="c-lightOrange f36-700">With Radin</h1>
      <p class="c-medGrey f16-400">
        Buy and sell digital currencies using over 350 different payment
        methods.
      </p>
    </div>
    <FindOrder />
  </div>
</template>

<script>
import FindOrder from "./FindOrder.vue";
export default {
  components: { FindOrder },
  name: "Banner",
};
</script>

<style lang="scss" scoped>
.banner-title {
  gap: 12px;
  max-width: 350px;
  transform: translateY(-100px);
  display: none;
}
.banner {
  position: relative;
  height: 900px;
  gap: 50px;
  &::before {
    content: " ";
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    background: url("../../assets/Background/Landing.svg");
    background-size: 100%;
    z-index: -1;
    transform: translateY(-50px) scale(1.2, 1.2);
    background-repeat: no-repeat;
  }
}
@media only screen and(max-width:1024px) {
  .banner::before {
    content: none;
  }
  .banner {
    flex-direction: column !important;
  }
  .banner-title {
    transform: unset !important;
    display: unset !important;
  }
  .find-order {
    transform: unset !important;
  }
}
</style>
