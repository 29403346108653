<template>
  <div class="homepage container columnd">
    <Banner />
    <Cards />
    <Table />
    <Features />
    <Steps />
    <Blogs />
    <TradeCard />
  </div>
</template>

<script>
import Banner from "./Banner.vue";
import Cards from "./Cards.vue";
import Table from "./Table.vue";
import Features from "./Features.vue";
import Steps from "./Steps.vue";
import Blogs from "./Blogs.vue";
import TradeCard from "./TradeCard.vue";
export default {
  name: "HomePage",
  components: {
    Banner,
    Cards,
    Table,
    Features,
    Steps,
    Blogs,
    TradeCard,
  },
};
</script>

<style lang="scss" scoped>
.homepage {
  gap: 96px;
}
</style>
