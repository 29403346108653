<template>
  <div class="tradecard columnd">
    <div class="title columnd">
      <h1 class="f22-700 c-lightOrange align-self-start">
        Start trading on Radin
      </h1>
      <p class="f14-400 c-medGrey align-self-start">
        Sign up today to receive your free digital wallet. You can immediately
        start buying or selling Bitcoin, hassle-free.
      </p>
    </div>
    <button class="primary-btn justify-center align-self-end">
      Start Trading
    </button>
  </div>
</template>

<script>
export default {
  name: "TradeCard",
};
</script>

<style lang="scss" scoped>
.primary-btn {
  max-width: 207px;
}
.tradecard {
  margin-bottom: 80px;
  background: url("../../assets/Background/tradecardbg.svg");
  //   background: var(--white);
  box-shadow: 0px 0px 250px rgba(115, 169, 166, 0.16);
  border-radius: 8px;
  padding: 12px 24px;
  gap: 32px;
  .title {
    gap: 16px;
  }
}
</style>
