<template>
  <div class="steps-container columnd w100 text-start">
    <h1 class="f22-700 c-lightOrange">3 Steps to Tarde in Radin</h1>
    <div
      v-for="(item, index) in cards"
      :key="index"
      class="rowd steps"
      @mouseenter="item.underline = true"
      @mouseleave="item.underline = false"
      :class="item.class"
    >
      <inline-svg class="circle-svg" :src="item.img" />
      <div class="texts columnd">
        <span class="f18-700 c-darkBlue"> {{ item.title }} </span>
        <p class="f14-400 c-darkGrey">{{ item.text }}</p>
        <transition name="scalex">
          <div v-if="item.underline" class="underline"></div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Steps",
  data() {
    return {
      cards: [
        {
          img: require("../../assets/Icons/circle1.svg"),
          title: "Register in Website",
          text: "Paxful makes it safe and easy for you to trade Bitcoin. Find the bestPaxful makes it safe and easy for you to trade Bitcoin. Find the bestPaxful makes it safe and easy for you to trade Bitcoin. ",
          underLine: false,
          class: "align-self-start",
        },
        {
          img: require("../../assets/Icons/circle2.svg"),
          title: "Register in Website",
          text: "Paxful makes it safe and easy for you to trade Bitcoin. Find the bestPaxful makes it safe and easy for you to trade Bitcoin. Find the bestPaxful makes it safe and easy for you to trade Bitcoin. ",
          underLine: false,
          class: "align-self-end",
        },
        {
          img: require("../../assets/Icons/circle3.svg"),
          title: "Register in Website",
          text: "Paxful makes it safe and easy for you to trade Bitcoin. Find the bestPaxful makes it safe and easy for you to trade Bitcoin. Find the bestPaxful makes it safe and easy for you to trade Bitcoin. ",
          underLine: false,
          class: "align-start",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.steps-container {
  gap: 40px;
  .steps {
    .texts {
      gap: 12px;
    }
    gap: 12px;
    span,
    p {
      max-width: 588px;
      transition: 500ms;
    }
  }
}
</style>
